import entuple from '../images/entuple.jpg';
import RTDS from '../images/RTDS.jpg';
import tmeic from '../images/tmeic.jpg';
import Typhoon from '../images/Typhoon-HIL.webp';
import ieeeup from '../images/ieee-up-section-logo.jpg';
import upn from "../images/upneda.png";
const SponsorsImg = [
    {
        id : 1,
        image: entuple
    }
    ,
    {
        id : 2,
        image: RTDS
    }
    ,
    {
        id : 3,
        image: tmeic
    }
    ,
    {
        id : 4,
        image: Typhoon
    }
    ,
    {
        id : 5,
        image: ieeeup
    },
    {
        id : 6,
        image: upn
    }

]

export default SponsorsImg;