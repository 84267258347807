import am from '../images/Members/Committee/Abheejeet Mohapatra.jpg';
import gd from "../images/speakers/Panelist/Ganesh_Das.jpg"
import aks from "../images/speakers/Panelist/AK_Saxena.jpg"
import sb from "../images/speakers/Silpa_Babu.jpg"
import scs from "../images/Members/Committee/SC-srivastava.jpg"
import as from "../images/Members/Committee/Ankush Sharma.jpg"
import sc from "../images/Members/Committee/Saikat_ChakraBarti.jpg"
import nc from "../images/Members/Committee/Naveen_ChilamKurti.jpg"
import makps from "../images/speakers/MAKP_singh.jpg"
import aa from "../images/speakers/Abhijit_Abhyankar.jpg"

const Panelist = [
    {
        title: "Prof. Abheejeet Mohapatra",
        description: "Moderator",
        bio:"",
        imageSrc: am,
    },
    {
        title: "Mr. Ganesh Das",
        description: "Chief - Collaboration & Innovation, TATA Power",
        bio:"A professional with around 35+ years of qualitative experience with high International Exposure in Energy Sector, Smart Grid, Clean Energy, Technology Management, Collaborations & Partnership Development, Strategy Planning, Innovation Management, Consumer Management & Analysis, Digital & Business Intelligence, Corporate Performance Management, Business and Market Development, customer relationship management in the area of Power, Smart Grid, Technology, Solar Renewables, Electric Vehicles Infrastructure, Automotive, Services. Currently heading as Chief Operating officer of Clean Energy International Incubation Centre (CEIIC), a joint initiative of Government of India & Tata Trusts and also Heading Collaborations & Smart Grid Lab with Tata Power Delhi Distribution Limited (A Joint venture of TATA Power Limited)",
        imageSrc: gd,
    },

    {
        title: "Mr A. K. Saxena",
        description: " Panelist Senior Fellow and Senior Director, Electricity and Renewables Division, TERI - The Energy and Resources Institute",
        bio:"Mr A. K. Saxena is engaged with the Electricity and Renewables Division of The Energy and Resources Institute (TERI) as its Senior Director. A veteran of India’s power sector with an experience spanning over four decades India his expertise lies in the areas of energy transition, just transition, smart distribution with energy storage, e-mobility, demand side management, smart grids, cross-border electricity trade, and more. Prior to his stint at TERI, Mr Saxena has worked with the Central Electricity Authority, Ministry of Power, and Central Electricity Regulatory Commission (CERC). He has also served as the Chair/Member/Convenor of Committees set up by CERC and is currently presiding as a Chairperson of the Industry Advisory Board of IIT-Kanpur and La Trobe University Research Academy. Mr Saxena holds a Master’s degree in Electrical Engineering and an Masters in Business Administration. He is also a Fellow of the Institution of Engineers (India) and a member of Institute of Electrical and Electronics Engineers (IEEE).",
        imageSrc: aks,
    },
    {
        title: "Dr. M.A.K.P. Singh",
        description: "Panelist",
        bio:"Dr. M.A.K.P.Singh, Member (Hydro), CEA and Chief Information Security Officer, Ministry of Power, is an Electrical Engineering Graduate from IIT Kanpur and have a MBA degree from IIT Delhi. Prior to joining Power Sector, he had worked for Schlumberger, Geo Services in Oil Industry and in Automobile leader Maruti Udyog Ltd. He joined Central Electricity Authority in 1992.  While in CEA he has worked for 12 years in Regional Power Committee RPC (Northern and Western Grid) earlier known as Regional Electricity Board.  He has 3 years’ experience of managing Northern Regional load dispatch center as Member Secretary, NRPC, New Delhi & WRPC Mumbai. In CEA, he had worked in other divisions like Fuel Management, System Planning & Project Appraisal, APDRP Cell before proceeding for deputation in NTPC in 2003. He has worked for 3 years in operation of Combined Cycle Gas Power Plant Kawas of NTPC.  He has worked for 5 years as Electrical Inspector in North Eastern Region (all seven sister states).  As Electrical Inspector he had the inspected all electrical installations of Government utilities like NHPC, NEEPCO, POWERGRID besides other industries. As Chief Engineer (IT & CS) he looked after the National Power Portal, Cyber Security and worked upon various Committee and Groups constituted for ensuring cyber security in Power Sector. He has also worked for 5 years in Hydro Sector for 5 years as Member (Power), Narmada Control Authority overseeing the O&M of 1450 MW Sardar Sarovar Hydro Plant and Dam.  He is very6 well versed with the crisis that may arise in the hydro as well as thermal plants and have a very good knowledge of the critical information infrastructure in power sector.",
        imageSrc: makps,
    },
    {
        title: "Prof. S C Srivastava",
        description: "Panelist",
        bio:"S. C. Srivastava received the Ph.D. degree in electrical engineering from the Indian Institute of Technology Delhi, New Delhi, India, in 1987. He was a Professor with the Department of Electrical Engineering, Indian Institute of Technology Kanpur, Kanpur, India, till 2020, and former Director of IIT Kanpur-LaTrobe University Research Academy and a Distinguished Visiting Professor. His research interests include power system stability and security analysis, synchrophasor applications, power system restructuring and ac/dc microgrids, and smart grids.",
        imageSrc: scs,
    },
    {
        title: "Prof. Saikat Chakrabarti",
        description: "Panelist",
        bio:"",
        imageSrc: sc,
    },
    {
        title: "Prof. Naveen Chilamkurti",
        description: 'Panelist',
        bio:"Prof. Naveen Chilamkurti  is currently acting Head of Cybersecurity Discipline La Trobe University, Computer Science and Information Technology, Melbourne, Australia. He is also the Director, La Trobe Cybersecurity Research Hub La Trobe University, Melbourne, Computer Science and IT, Melbourne, Australia. He obtained his Ph.D. degree from La Trobe University. He is also the Inaugural Editor-in-Chief for the International Journal of Wireless Networks and Broadband Technologies. He is currently serving as a Technical Editor for IEEE Wireless Communications and IEEE Communications Magazine. He has published about 190 journal and conference papers. His current research areas include cybersecurity, IDS, authentication, wireless sensor networks, and more.",
        imageSrc: nc,
    },
    {
        title: "Prof. Abhijit R. Abhyankar",
        description: "Panelist",
        bio:"Abhijit R. Abhyankar (PhD) is a Professor of Electrical Engineering Department at Indian Institute of Technology Delhi, New Delhi, India. He received PhD from IIT Bombay in 2007. He was a member of Global Future Council on Clean Electrification of World Economic Forum. He has acted as expert member of various committees established by the Central Electricity Regulatory Commission (CERC) to provide technical support to resolve regulatory issues. He was a member of National Reliability Council for Electricity (NRCE), set-up by Ministry of Power, Government of India, through Central Electricity Authority (CEA). He was also a member of Taskforce on PoC Transmission Pricing Review, constituted by Central Electricity Regulatory Commission (CERC), New Delhi. He has handled important projects and done consultancies for various government and private organizations. Recipient of ‘Teaching excellence award’ at IIT Delhi, he has co- developed an NPTEL web course titled – ‘Restructured Power Systems’.",
        imageSrc: aa,
    },
    {
        title: "Ms. Silpa Babu",
        description: "Panelist",
        bio: "Silpa is an accomplished professional with over 24 years of experience in renewable energy, specialising in the engineering of utility scale and rooftop solar plants, technical due diligence, sustainability, feasibility studies, energy and bankability assessments and strategic planning. She has held different positions in the Advisory, Developer, EPC and Manufacturing sectors, working for companies such as DNV, Cleanmax Solar, juwi India, TATA Power Solar and ABB. She has advised many Indian and international financial institutions and investors for over 35000 MW of solar assets.",
        imageSrc: sb,
    },
    {
        title: "Prof. Ankush Sharma",
        description: "Panelist",
        bio:"",
        imageSrc: as,
    },



]

export default Panelist;